@import-normalize;

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  // height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #50514f;
  background: white;
  height: 100%;
}

#root {
  position: relative;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-weight: 300;
  font-size: 3.9rem;
}

h2 {
  font-weight: 300;
  font-size: 3.1rem;
  margin-bottom: 0.4rem;
}

h3 {
  font-weight: 300;
  font-size: 2.5rem;
}

p {
  font-weight: 300;
  font-size: 1.5rem;
}

hr {
  margin: 2.5rem 0;
  border: 0.5px solid #e9eff4;
}
